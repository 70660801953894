define("discourse/plugins/Discourse Tag Group Actions/discourse/initializers/extend-for-tag-group-actions", ["exports", "discourse/components/search-advanced-options", "discourse/lib/plugin-api"], function (_exports, _searchAdvancedOptions, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(api) {
    api.modifyClass("component:tag-group-chooser", {
      actions: {
        onChange(value) {
          this._super(...arguments);
          if (this.onChangeSearchedTermField) {
            this.onChangeSearchedTermField("notInTagGroup", "_updateSearchTermForTagGroup", value);
          }
        }
      }
    });
    api.addBulkActionButton({
      label: "topics.bulk.close_deal",
      icon: "user-plus",
      class: "btn-default",
      visible: _ref => {
        let {
          siteSettings
        } = _ref;
        return siteSettings.tga_bulk_action_tag_group_name && siteSettings.tga_bulk_action_replace_tag_name;
      },
      action(_ref2) {
        let {
          performAndRefresh
        } = _ref2;
        performAndRefresh({
          type: "closeDeal"
        });
      },
      actionType: "performAndRefresh"
    });
  }
  const REGEXP_TAG_GROUP_PREFIX = /^(notInTagGroup:)/gi;
  var _default = _exports.default = {
    name: "extend-for-tag-group-actions",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.tag_group_action_enabled) {
        return;
      }
      const currentUser = container.lookup("current-user:main");
      if (currentUser && currentUser.staff) {
        _searchAdvancedOptions.default.reopen({
          init() {
            this._super();
            this.set("searchedTerms.notInTagGroup", null);
          },
          _updateSearchTermForTagGroup() {
            const match = this.filterBlocks(REGEXP_TAG_GROUP_PREFIX);
            let userFilter = this.get("searchedTerms.notInTagGroup");
            let searchTerm = this.searchTerm || "";
            let keyword = "notInTagGroup";
            if (userFilter && userFilter.length !== 0) {
              userFilter = userFilter[0].replaceAll(" ", "_");
              if (match.length !== 0) {
                searchTerm = searchTerm.replace(match[0], `${keyword}:${userFilter}`);
              } else {
                searchTerm += ` ${keyword}:${userFilter}`;
              }
              this._updateSearchTerm(searchTerm);
            } else if (match.length !== 0) {
              searchTerm = searchTerm.replace(match[0], "");
              this._updateSearchTerm(searchTerm);
            }
          },
          didReceiveAttrs() {
            this._super(...arguments);
            this.setSearchedTermValue("searchedTerms.notInTagGroup", REGEXP_TAG_GROUP_PREFIX);
          }
        });
      }
      (0, _pluginApi.withPluginApi)("0.11.0", api => initialize(api, container));
    }
  };
});