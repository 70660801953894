define("discourse/plugins/Discourse Tag Group Actions/discourse/connectors/advanced-search-options-below/tag-group-advanced-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      return component.currentUser && component.currentUser.staff;
    }
  };
});